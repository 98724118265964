import { v4 as uuidv4 } from "uuid";
import request from "./requestConfig";
const prefix = "/licenceKey";

export function getUserLicence() {  
  return request.get(`${prefix}/`);
}


export default {  getUserLicence };
