import * as types from "./actionTypes";
import { hideLoading, setError, setLoading } from "./commonActions";

import licencingKeyApi from "../../api/liscenceKeyApi";

// export function loadAdvancedSettingsSuccess(items) {
//   return { type: types.LOAD_ADVANCEDSETTINGS_SUCCESS, payload: items };
// }

export function loadUserLicence() {
  return async (dispatch) => {
    try {
      dispatch(setLoading());
      const result = await licencingKeyApi.getUserLicence();
      const items = result.data;
      if (result.status === 200)
      {      dispatch(hideLoading());
        // dispatch(loadAdvancedSettingsSuccess(items));
      }
      else dispatch(setError(result));
      dispatch(hideLoading());
      return items;
    } catch (error) {
      dispatch(setError(error));
      dispatch(hideLoading());
    }
  };
}


